import { Authority } from '@/shared/security/authority';

const Config = () => import('@/entities/setup/config/config.vue');
const ConfigUpdate = () => import('@/entities/setup/config/config-update.vue');

const SqlQuery = () => import('@/entities/setup/sql-query/sql-query.vue');
const SqlQueryUpdate = () => import('@/entities/setup/sql-query/sql-query-update.vue');

const Report = () => import('@/entities/setup/report/report.vue');
const ReportUpdate = () => import('@/entities/setup/report/report-update.vue');

const ReportCategory = () => import('@/entities/setup/report-category/report-category.vue');
const ReportCategoryUpdate = () => import('@/entities/setup/report-category/report-category-update.vue');

const ReportGroup = () => import('@/entities/setup/report-group/report-group.vue');
const ReportGroupUpdate = () => import('@/entities/setup/report-group/report-group-update.vue');

export default [
  /*
   *  Human Resources
   */
  //  <a href="https://aurora.roots.grupo-valco.com/factorycontrol/people">HR People</a>
  //  <a href="https://aurora.roots.grupo-valco.com/factorycontrol/personalpins">Personal Pins</a>

  /*
   *  Hawkeye
   */
  // https://hawkeye.roots.grupo-valco.com/

  /*
   *  Laravel Nova
   */
  // <https://aurora.roots.grupo-valco.com/roots>Laravel Nova</a>

  /*
   *  SQL Queries
   */
  {
    path: '/setup/sql-query',
    name: 'SqlQuery',
    component: SqlQuery,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/sql-query/new',
    name: 'SqlQueryCreate',
    component: SqlQueryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/sql-query/:sqlQueryId/edit',
    name: 'SqlQueryEdit',
    component: SqlQueryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/sql-query/:sqlQueryId/view',
    name: 'SqlQueryView',
    // component: SqlQueryDetails,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/sql-query/:sqlQueryId/duplicate',
    name: 'SqlQueryDuplicate',
    component: SqlQueryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  /*
   *  Report
   */
  {
    path: '/setup/report',
    name: 'Report',
    component: Report,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report/new',
    name: 'ReportCreate',
    component: ReportUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report/:reportId/edit',
    name: 'ReportEdit',
    component: ReportUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  // {
  //   path: '/setup/report/:reportId/view',
  //   name: 'ReportView',
  //   component: ReportDetails,
  //   meta: { authorities: [Authority.ADMIN] },
  // },
  {
    path: '/setup/report/:reportId/duplicate',
    name: 'ReportDuplicate',
    component: ReportUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  /*
   *  Report Groups
   */
  {
    path: '/setup/report-group',
    name: 'ReportGroup',
    component: ReportGroup,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report-group/new',
    name: 'ReportGroupCreate',
    component: ReportGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report-group/:reportGroupId/edit',
    name: 'ReportGroupEdit',
    component: ReportGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  // {
  //   path: '/setup/report-group/:reportGroupId/view',
  //   name: 'ReportGroupView',
  //   component: ReportGroupDetails,
  //   meta: { authorities: [Authority.ADMIN] },
  // },
  {
    path: '/setup/report-group/:reportGroupId/duplicate',
    name: 'ReportGroupDuplicate',
    component: ReportGroupUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },

  /*
   *  Report Categories
   */
  {
    path: '/setup/report-category',
    name: 'ReportCategory',
    component: ReportCategory,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report-category/new',
    name: 'ReportCategoryCreate',
    component: ReportCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/report-category/:reportCategoryId/edit',
    name: 'ReportCategoryEdit',
    component: ReportCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  // {
  //   path: '/setup/report-category/:reportCategoryId/view',
  //   name: 'ReportCategoryView',
  //   component: ReportCategoryDetails,
  //   meta: { authorities: [Authority.ADMIN] },
  // },
  {
    path: '/setup/report-category/:reportCategoryId/duplicate',
    name: 'ReportCategoryDuplicate',
    component: ReportCategoryUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/config',
    name: 'Config',
    component: Config,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/config/new',
    name: 'ConfigCreate',
    component: ConfigUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/setup/config/:configId/edit',
    name: 'ConfigEdit',
    component: ConfigUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  // {
  //   path: '/setup/config/:configId/view',
  //   name: 'ConfigView',
  //   component: ConfigDetails,
  //   meta: { authorities: [Authority.ADMIN] },
  // },
  {
    path: '/setup/config/:configId/duplicate',
    name: 'ConfigDuplicate',
    component: ConfigUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
];
