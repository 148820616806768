/**
 * @see <a href='https://vuetifyjs.com/en/components/data-tables/#crud-actions'>data-tables/#crud-actions</a>
 */

/* eslint-disable */
import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
import { AlertService } from '@gv/ammo-grome';
import {
  AlertType,
  CmmsMaintenanceService,
  DateUtils,
  ErpDateUtils,
  ErpLanguage,
  FunctionAuthorization,
  GardianService,
  IFunctionAuthorization,
  IZQM0,
  IZQM1_1,
  LocalMenuService,
  QueryService,
  SubprogService,
  ZQM0_1,
  ZQMO,
} from '@gv/ammo-astra';

import { Query } from '@gv/ammo-astra/dist/services/query/query';

import { AxiosSingleton } from '@/shared/config/axios-interceptor';

import { maxValue, minValue, required } from 'vuelidate/lib/validators';
import { maintenanceCodes } from '@/entities/spe/cmms-maintenance/mock/mock.service';

const erpFunction = 'GESZQM';

const validations: any = {
  editedItem: {
    ITMREF: {
      required,
    },
    QTY: {
      required,
      minLength: minValue(1),
      maxLength: maxValue(20),
    },
    UOM: {
      required,
    },
    ZMAICOD: {
      required,
    },
    ZMAITYP: {
      required,
    },
  },

  zqm: {
    ZQM0_1: {
      FCY: {
        required,
      },
      WST: {
        required,
      },
    },
    ZQM1_1: {},
    hasLines: (val: { [k: string]: any }) => val.ZQM1_1.length > 0,
  },
};

@Component({
  validations,
})
export default class CmmsMaintenance extends Vue {
  /**
   * Function Authorization
   */
  public isFunctionAuthorizationFetching = false;
  public functionAuthorization: IFunctionAuthorization = new FunctionAuthorization(erpFunction, false, false, false, null, [], [], []);

  public dialog = false;
  public dialogDelete = false;

  public isSaving = false;
  public isLoading = false;
  public isFetching = false;

  public currentLanguage = '';
  public zqm: IZQM0 = this.initialize();
  public productionSiteWorkCenters: Array<{ [k: string]: any }> = [];
  public selectedAuthorizedProductionSite: { [k: string]: string } = null;
  public selectedProductionSiteWorkCenter: { [k: string]: string } = null;
  public selectedProductFromWorkCenter: { [k: string]: string } = null;
  public maintenanceTypes: Array<{ [k: string]: any }> = [];
  public maintenanceCodes: Array<{ [k: string]: any }> = maintenanceCodes;
  public products: Array<{ [k: string]: any }> = [];
  public editedItemAvailableStock = 0;
  public editedItemProduct: { [k: string]: any } = {};
  public editedIndex = -1;

  public tableHeaders: Array<any> = [
    {
      text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.ITMREF'),
      align: 'start',
      value: 'ITMREF',
    },
    {
      text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.QTY'),
      value: 'QTY',
    },
    {
      text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.UOM'),
      value: 'UOM',
    },
    {
      text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.ZMAICOD'),
      value: 'ZMAICOD',
    },
    {
      text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.ZMAITYP'),
      value: 'ZMAITYP',
    },
    { text: this.$t('wibaultApp.maintenanceLine.ZQM.ZQM1_1.tableAction'), value: 'actions', sortable: false },
  ];

  public editedItem: IZQM1_1 = {
    ITMREF: '',
    QTY: 1,
    UOM: '',
    ZMAITYP: '',
    ZMAICOD: '',
  };
  public defaultItem: IZQM1_1 = {
    ITMREF: '',
    QTY: 1,
    UOM: 'UN',
    ZMAITYP: '',
    ZMAICOD: '',
  };

  /**
   * ZQM0_1 posted date menu
   */
  public zqmo0_1__ZMAIDATmenu = false;
  private erpLanguage: ErpLanguage = ErpLanguage.POR;

  /**
   * Injections
   * @private
   */
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('subprogService')
  private subprogService: () => SubprogService;

  @Inject('localMenuService')
  private localMenuService: () => LocalMenuService;

  @Provide('cmmsMaintenanceService')
  private cmmsMaintenanceService = () => new CmmsMaintenanceService(AxiosSingleton.getInstance());

  /**
   * Posted date default format
   */
  public get zqmo0_1__ZMAIDATComputedDateFormatted(): string | null {
    return DateUtils.toFormat(ErpDateUtils.toISOString(this.zqm.ZQM0_1.ZMAIDAT), 'DD-MM-YYYY');
  }

  public get authorizedProductionSites(): { [k: string]: string }[] {
    return this.functionAuthorization.authorizedSites
      .filter(it => it.mfgflg0 === 2)
      .map(it => ({
        fcy0: it.fcy0,
        fcynam0: it.fcynam0,
      }));
  }

  public get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
  }

  /**
   * Expose ErpDateUtils functions
   */
  public toISOString(formattedDate: string): string {
    return ErpDateUtils.toISOString(formattedDate);
  }

  public toFormat(dateAsISO: string): string {
    return ErpDateUtils.toFormat(dateAsISO);
  }

  /**
   * Instantiate and initialize the entity, update screen fields
   */
  public initialize(): ZQMO {
    return new ZQMO(new ZQM0_1(null, '', '', ''), []);
  }

  public productUnits() {
    const units: string[] = [];
    if (!this.editedItem.ITMREF) return units;
    const product = this.products.find(it => it['ITM__ITMREF_0'] === this.editedItem.ITMREF);
    units.push(product.ITM__STU_0);
    return units;
  }

  /**
   *
   * @param val
   */
  @Watch('dialog')
  onDialogChanged(val) {
    if (val) {
      this.gridEditItemClear();
    }
    return val || this.gridClose();
  }

  @Watch('dialogDelete')
  onDialogDelete(val) {
    val || this.gridCloseDelete();
  }

  /**
   * onSelected functions
   * @param val
   */
  @Watch('selectedAuthorizedProductionSite', { immediate: true })
  onSelectedAuthorizedProductionSite(val: { [k: string]: any }) {
    // console.log(val);
    if (val) {
      this.retrieveWorkCenters(val.fcy0);
      this.zqm.ZQM0_1.FCY = val.fcy0;
    }
  }

  @Watch('selectedProductionSiteWorkCenter', { immediate: true })
  onSelectedWorkCenterChanged(val: { [k: string]: any }): void {
    // console.log(val);
    if (val) {
      this.retrieveProducts(val.MWS__WST_0);
      this.zqm.ZQM0_1.WST = val.MWS__WST_0;
    }
  }

  @Watch('selectedProductFromWorkCenter', { immediate: true })
  onSelectedProductFromWorkCenter(val: { [k: string]: any }): void {
    // console.log(val);
    if (val) {
      this.retrieveProducts(val.AXX__TEXTE_0);
    }
  }

  /**
   * Local menu 6209
   */
  public retrieveMaintenanceTypes(): void {
    this.localMenuService()
      .localMenu(6209, this.erpLanguage)
      .then(res => {
        this.maintenanceTypes = res.data;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
        this.maintenanceTypes = [];
      });
  }

  /**
   *  Retrieve functions
   */
  public retrieveFunctionAuthorization(): Promise<boolean> {
    return new Promise(resolve => {
      this.isFunctionAuthorizationFetching = true;

      this.gardianService()
        .functionAuthorization({ function: erpFunction })
        .then(param => {
          this.functionAuthorization.accessTypeObject = param.accessTypeObject;
          this.functionAuthorization.authorizationSite = param.authorizationSite;
          this.functionAuthorization.authorizedAccessTypeObject = param.authorizedAccessTypeObject;
          this.functionAuthorization.defaultSite = param.defaultSite;
          this.functionAuthorization.authorizedCompanies.push(...param.authorizedCompanies);
          this.functionAuthorization.authorizedSites.push(...param.authorizedSites);
          this.functionAuthorization.authorizedSalesrep.push(...param.authorizedSalesrep);
          this.isFunctionAuthorizationFetching = false;
          resolve(true);
        })
        .catch(error => {
          resolve(false);
          this.isFunctionAuthorizationFetching = false;
          console.error(error);
          this.alertService().showHttpError(this, error.response);
        });
    });
  }

  public retrieveWorkCenters(site: string): void {
    const params = {
      language: this.erpLanguage,
      site: site,
    };
    this.isLoading = true;
    this.queryService()
      .query(Query.ERP_SAGE_X3_MANUFACTURING_WORK_CENTERS, params)
      .then(res => {
        this.productionSiteWorkCenters = res.data;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public retrieveProducts(workCenter: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const params: { [k: string]: any } = {
        language: this.erpLanguage,
        site: this.zqm.ZQM0_1.FCY,
        costingDimension: workCenter,
        description: '',
        gamma: 'F',
        group: '',
        family: '',
      };
      this.queryService()
        .query(Query.ERP_SAGE_X3_MAINTENANCE_WORK_CENTER_COST_DIMENSION_ITEMS, params)
        .then(res => {
          this.products = res.data;
          this.retrieveMaintenanceTypes();
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
          this.products = [];
          this.editedItemProduct = {};
          reject(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  /**
   * Filter functions
   * @param item
   * @param queryText
   * @param itemText
   */
  public filterAuthorizedProductionSites(item: any, queryText: string, itemText: string) {
    const code = item.fcy0.toLowerCase();
    const description = item.fcynam0.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  public filterWorkCenters(item: any, queryText: string, itemText: string) {
    const code = item.MWS__WST_0.toLowerCase();
    const description = item.MWS__WSTDESAXX_0.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  public filterProducts(item: any, queryText: string, itemText: string) {
    const code = item.ITM__ITMREF_0.toLowerCase();
    const description = item.AXX__TEXTE_0.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  public filterMaintenanceCodes(item: any, queryText: string, itemText: string) {
    const code = item.code.toLowerCase();
    const description = item.description.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  public filterMaintenanceTypes(item: any, queryText: string, itemText: string) {
    const code = item.CODE_0.toLowerCase();
    const description = item.LANMES_0.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  created(): void {
    this.retrieveFunctionAuthorization().then(response => {
      if (response) {
        this.currentLanguage = this.$store.getters.currentLanguage;
        this.$store.watch(
          () => this.$store.getters.currentLanguage,
          () => {
            this.currentLanguage = this.$store.getters.currentLanguage;
          }
        );
        this.setDefaults();
      } else {
        this.$router.go(-1);
      }
    });
  }

  /**
   * Save all changes
   */
  public save(): void {
    this.isSaving = true;
    if (this.$v.$invalid) {
      this.$v.$touch();
      console.log('fields are empty');
      if (this.zqm.ZQM1_1.length < 1) {
        this.$v.$touch();
      } else {
        this.cmmsMaintenanceService()
          .create(this.zqm)
          .then(param => {
            this.cancel();
            this.alertService().showAlert(this, AlertType.Success, 'wibaultApp.maintenanceHeader.created', {
              param: param.ZQM0_1.VCRNUM,
            });
          })
          .catch(error => {
            // this.isSaving = false;
            this.alertService().showHttpError(this, error.response);
          });
      }
    }
  }

  /**
   * Reset maintenance header and table data
   */
  public cancel(): void {
    this.selectedAuthorizedProductionSite = null;
    this.selectedProductionSiteWorkCenter = null;
    this.zqm.ZQM1_1 = [];
    this.isSaving = false;
    this.$v.$touch();
    this.$v.$reset();
    // this.zqm = this.initialize();
    // this.setDefaults();
  }

  /**
   * Edit item
   * @param item
   */
  public gridEditItem(item): void {
    this.resetGridEditItemValidations();
    this.editedIndex = this.zqm.ZQM1_1.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  /**
   * Delete Item
   * @param item
   */
  public gridDeleteItem(item) {
    this.editedIndex = this.zqm.ZQM1_1.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  /**
   * Delete Item confirm
   */
  public gridDeleteItemConfirm(): void {
    this.zqm.ZQM1_1.splice(this.editedIndex, 1);
    this.gridCloseDelete();
  }

  /**
   * Close grid after saving
   */
  public gridClose(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  /**
   * Close delete
   */
  public gridCloseDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  /**
   * Add items to table
   */
  public gridSave(): void {
    if (this.isGridEditItemDataInvalid()) {
      return;
    }
    if (this.editedIndex > -1) {
      Object.assign(this.zqm.ZQM1_1[this.editedIndex], this.editedItem);
    } else {
      this.zqm.ZQM1_1.push(this.editedItem);
      this.editedIndex = 0;
      setTimeout(() => {
        this.isSaving = false;
      }, 0);
    }
    this.gridClose();
  }

  /**
   * Clear items
   */
  public gridEditItemClear() {
    this.editedItemProduct = {};
    this.editedItemAvailableStock = 0;
    this.resetGridEditItemValidations();
  }

  /**
   * Reset Item Validations
   */
  public resetGridEditItemValidations(): void {
    this.$v.editedItem.$reset();
  }

  /**
   * Perform validation over the edited item
   */
  public isGridEditItemDataInvalid(): boolean {
    // Fire all validations
    this.$v.editedItem.$touch();
    return this.$v.editedItem.$invalid;
  }

  /**
   * Vuelidator
   * @param model
   * @param options
   */

  public vuelidateErrors(model: { [k: string]: any }, options?: { [k: string]: any }): string[] {
    // public vuelidateErrors(model, options: { [k: string]: any }): Array<string> {
    // console.log(`Dirty=${model.$dirty}, anyError=${model.$anyError}`);

    // Property received some sort of interaction from the user
    // console.log("!model.$dirty=" + !model.$dirty);

    if (!model.$dirty) return [];

    const errors = [];

    if (model.hasOwnProperty('required') && !model.required) {
      errors.push(this.$t('entity.validation.required'));
    }

    if (model.hasOwnProperty('minValue') && !model.minValue) {
      errors.push(this.$t('entity.validation.min', { min: options.min }));
    }

    if (model.hasOwnProperty('maxValue') && !model.maxValue) {
      errors.push(this.$t('entity.validation.max', { max: options.max }));
    }

    // if (model.hasOwnProperty("minLength") && !model.minLength) {
    //   errors.push(this.$t("entity.validation.minlength", { min: options.min }));
    // }
    //
    // if (model.hasOwnProperty("maxLength") && !model.maxLength) {
    //   errors.push(this.$t("entity.validation.maxlength", { max: options.max }));
    // }
    return errors;
  }

  /** Check if it is number
   * @param evt
   */
  public isNumber(evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed: string = evt.key;

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }

  /**
   * Set new entity defaults
   * @private
   */
  private setDefaults() {
    /* Site */
    const site = this.functionAuthorization.authorizedSites.find(it => it.fcy0 === this.functionAuthorization.defaultSite);
    if (site) {
      this.selectedAuthorizedProductionSite = site;
    }
    /* Posted date */
    this.zqm.ZQM0_1.ZMAIDAT = ErpDateUtils.toFormat(DateUtils.nowAsISOString());
  }
}
