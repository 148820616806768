import { Query } from '@gv/ammo-astra/dist/services/query/query';
import {ErpLanguage, PrintingService, QueryService} from '@gv/ammo-astra';

export default class SawmillQueryService {
  private readonly baseLanguage: ErpLanguage = ErpLanguage.POR;

  private readonly queryService: any;

  constructor(queryService: any) {
    this.queryService = queryService;
  }

  public query(slug: Query, params: { [k: string]: any }): Promise<any> {
    return this.queryService().query(slug, params);
  }

  public retrieveProductionSites(): Promise<any> {
    const params = {
      accountingSite: 0,
      dadsSite: 0,
      productionSite: 2,
      purchaseSite: 0,
      salesSite: 0,
      storageSite: 0,
    };

    return this.query(Query.ERP_SAGE_X3_SETUP_ORGANIZATIONAL_STRUCTURE_SITES, params);
  }

  public retrieveFamilies(): Promise<any> {
    return this.query(Query.ERP_SAGE_X3_SAWMILL_CATEGORIES, { language: this.baseLanguage });
  }

  public retrieveProducts(family: string, customStatisticalGroupCode4: string) {
    const params = {
      language: this.baseLanguage,
      family: family,
      customStatisticalGroupCode4: customStatisticalGroupCode4,
    };

    return this.query(Query.ERP_SAGE_X3_SAWMILL, params);
  }

  public retrieveProductBOMCodes(site: string, product: string): Promise<any> {
    const params = {
      language: this.baseLanguage,
      site: site,
      product: product,
    };

    return this.query(Query.ERP_SAGE_X3_MANUFACTURING_PLANNING_WORK_ORDERS_SELECT_MANUFACTURING_BOM_CODE, params);
  }

  public retrieveProductSiteData(site: string, product: string, bomCode: number): Promise<any> {
    const params = {
      language: this.baseLanguage,
      site: site,
      product: product,
      bomCode: bomCode,
    };

    return this.query(Query.ERP_SAGE_X3_COMMON_DATA_PRODUCTS_SITE, params);
  }

  public retrieveTrackHistory(site: string, product: string, user: string, revenueType: number): Promise<any> {
    const params = {
      language: this.baseLanguage,
      manufacturingSite: site,
      product: product ? product : '',
      user: user,
      revenueType: revenueType,
      until: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
    };

    return this.query(Query.ERP_SAGE_X3_MANUFACTURING_PLANNING_WORK_ORDERS_STATUS_SELECT_PRODUCT_UNTIL, params);
  }
}
