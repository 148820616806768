import { Component, Vue, Inject } from 'vue-property-decorator';

import { IImosScmPartRotationHeader } from '@/shared/model/imos-scm-part-rotation-header.model';
import ImosScmPartRotationHeaderService from './imos-scm-part-rotation-header.service';
import AlertService from '@/shared/alert/alert.service';

@Component
export default class ImosScmPartRotationHeaderDetails extends Vue {
  @Inject('imosScmPartRotationHeaderService') private imosScmPartRotationHeaderService: () => ImosScmPartRotationHeaderService;
  @Inject('alertService') private alertService: () => AlertService;

  public imosScmPartRotationHeader: IImosScmPartRotationHeader = {};

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.imosScmPartRotationHeaderId) {
        vm.retrieveImosScmPartRotationHeader(to.params.imosScmPartRotationHeaderId);
      }
    });
  }

  public retrieveImosScmPartRotationHeader(imosScmPartRotationHeaderId) {
    this.imosScmPartRotationHeaderService()
      .find(imosScmPartRotationHeaderId)
      .then(res => {
        this.imosScmPartRotationHeader = res;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public previousState() {
    this.$router.go(-1);
  }
}
