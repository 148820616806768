import { Authority } from '@/shared/security/authority';
import CmmsMaintenanceComponent from '@/entities/spe/cmms-maintenance/cmms-maintenance.vue';

export default [
  {
    path: '/cmms/maintenances',
    name: 'cmms-maintenances',
    component: CmmsMaintenanceComponent,
    meta: { authorities: [Authority.USER] },
  },
];
