import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
//const Entities = () => import('@/entities/entities.vue');

const XConverter = () => import('@/entities/x-converter/x-converter.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/acl-247/x-converter',
    name: 'x-converter',
    component: XConverter,
    meta: { authorities: [Authority.ADMIN, Authority.ACL_247] },
  },
];
