import { IACL247QuerySearchRequest } from '@/shared/model/acl247/query-search-request.model';
import { Query } from '@gv/ammo-astra/dist/services/query/query';

export default class ACL247QueryService {
  private readonly queryService;

  constructor(queryService) {
    this.queryService = queryService;
  }

  public query(slug: Query, params: { [k: string]: any }): Promise<any> {
    return this.queryService().query(slug, params);
  }

  /**
   * Gets records for erp-sage-x3-acl-247-starter-processor
   * @param params
   */
  public acl247ErpStarterProcessor(params: IACL247QuerySearchRequest): Promise<any> {
    return this.queryService().query(Query.ERP_SAGE_X3_ACL_247_STARTER_PROCESSOR, params);
  }

  /**
   * Get tags counts
   * @param params
   */
  public acl247ErpTagsCount(params: IACL247QuerySearchRequest): Promise<any> {
    return this.queryService().query(Query.ERP_SAGE_X3_ACL_247_TAGS_COUNT, params);
  }

  /**
   * Get tags
   * @param params
   */
  public acl247ErpTags(params: IACL247QuerySearchRequest): Promise<any> {
    return this.queryService().query(Query.ERP_SAGE_X3_ACL_247_TAGS, params);
  }
}
