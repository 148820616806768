import { Authority } from '@/shared/security/authority';

const StockMiscellaneousIssue = () => import('@/entities/stock/miscellaneous-issue/miscellaneous-issue.vue');

export default [
  {
    path: '/stock/miscellaneous-issues',
    name: 'stock-miscellaneous-issues',
    component: StockMiscellaneousIssue,
    meta: { authorities: [Authority.USER] },
  },
];
