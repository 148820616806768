import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import { AlertType, PrintingService, QueryService } from '@gv/ammo-astra';
import { AlertService } from '@gv/ammo-grome';
import SawmillReportService from '@/entities/sawmill/sawmill-report.service';
import SawmillQueryService from '@/entities/sawmill/sawmill-query.service';

@Component
export default class TrackHistory extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Provide('sawmillQueryService')
  private sawmillQueryService = () => new SawmillQueryService(this.queryService);

  @Provide('printingService')
  private printingService = () => new PrintingService(AxiosSingleton.getInstance());

  @Provide('sawmillReportService')
  private sawmillReportService = () => new SawmillReportService(this, this.$store, this.printingService);

  @Prop(String) readonly site!: string | null;
  @Prop(String) readonly product!: string | null;
  @Prop(Boolean) readonly isHistoryUpToDate!: boolean | null;

  public history: { [k: string]: any }[] = [];

  public get tableHeaders(): { [k: string]: any }[] {
    const headers: { [k: string]: any }[] = [
      { text: this.$t('wibaultApp.productTrack.ZMKP.MTK0_1.MFGNUM'), value: 'MFG__MFGNUM_0' },
      { text: this.$t('wibaultApp.productTrack.ZMKP.MTK0_1.MFGTRKNUM'), value: 'MKI__ZMFGTRKNUMLST_0' },
      { text: this.$t('wibaultApp.productTrack.ZMKP.MTK0_1.MFGTRKDAT'), value: 'MFG__CREDAT_0' },
    ];

    if (!this.product) {
      headers.push(
        { text: this.$t('wibaultApp.productTrack.ZMKP.MTK2_2.ITMREF'), value: 'ITM__ITMREF_0' },
        { text: this.$t('wibaultApp.productTrack.ZMKP.MTK2_2.ITMDES1'), value: 'AXX__TEXTE_0' }
      );
    }

    headers.push({ text: 'Actions', align: 'end', value: 'actions' });

    return headers;
  }

  @Watch('site')
  onSiteChanged(site: string | null) {
    this.retrieveTrackingHistory(site, this.product);
  }

  @Watch('product')
  onProductChanged(product: string | null) {
    this.retrieveTrackingHistory(this.site, product);
  }

  @Watch('isHistoryUpToDate')
  onIsHistoryUpToDateChanged(val: boolean) {
    if (!val) {
      this.retrieveTrackingHistory(this.site, this.product);
      this.$emit('history-updated');
    }
  }

  created(): void {
    this.retrieveTrackingHistory(this.site, this.product);
  }

  public retrieveTrackingHistory(site: string, product: string): void {
    this.sawmillQueryService()
      .retrieveTrackHistory(site, product, this.$store.getters.account?.erpUser?.usr0, 1)
      .then(res => {
        this.history = res.data.map(it =>
          Object.assign({}, it, {
            MFG__CREDAT_0: new Date(it.MFG__CREDAT_0).toISOString().split('T')[0].split('-').reverse().join('-'),
          })
        );
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public print(tracking: string): void {
    if (!tracking || tracking.trim() === '') {
      this.alertService().showAlert(
        this,
        AlertType.Error,
        `${this.$t('wibaultApp.productTrack.ZMKP.MTK0_1.MFGTRKNUM')}! ${this.$t('entity.validation.required')}`
      );
      return;
    }
    this.sawmillReportService().printLabel(this.site, tracking)
      .then(() => {
        this.alertService().showAlert(this, AlertType.Success, 'Printing successful!');
      })
      .catch(err => {
        this.alertService().showError(this, err.message);
      });
  }
}
