import { Authority } from '@/shared/security/authority';
const Label = () => import('@/entities/spe/label/print/print.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/label/print',
    name: 'print',
    component: Label,
    meta: { authorities: [Authority.USER] },
  },
];
