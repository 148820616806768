export interface IStep {
  name?: string;
  isSaving?: boolean;
  done?: boolean;
  success?: boolean;
  message?: string;
  response?: any;
}

export class Step implements IStep {
  constructor(
    public name?: string,
    public isSaving?: boolean,
    public done?: boolean,
    public success?: boolean,
    public message?: string,
    public response?: any
  ) {
    this.isSaving = false;
    this.done = false;
    this.success = false;
    this.message = null;
    this.response = null;
  }
}
