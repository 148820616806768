import { Component, Vue } from 'vue-property-decorator';
import CreateWoAndTrack from './create-work-order-and-track.vue';
import TrackHistory from './track-history.vue';

@Component({
  components: {
    'create-wo-and-track': CreateWoAndTrack,
    'track-history': TrackHistory,
  },
})
export default class Sawmill extends Vue {
  public showButton: boolean | null = null;

  public tab = null;
  public readonly tabs: any[] = [{ component: 'create-wo-and-track' }, { component: 'track-history' }];

  public product: string | null = null;
  public site: { [k: string]: any } | null = null;
  public isHistoryUpToDate = true;
}
