import {
  ErpLanguage,
  IPrinting,
  Printing,
  Printing_GRP1,
  Printing_GRP2,
  Printing_GRP3,
} from '@gv/ammo-astra';
import { Store } from 'vuex';
import { AccountStateStorable } from '@/shared/config/store/account-store';
import Vue from "vue";

export default class SawmillReportService {
  private readonly baseLanguage: ErpLanguage = ErpLanguage.POR;

  constructor(private instance: Vue, private store: Store<AccountStateStorable>, private printingService: any) {
    this.instance = instance;
    this.store = store;
    this.printingService = printingService;
  }

  public printLabel(site :string, tracking: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const user: string | undefined = this.store.getters.account?.erpUser?.usr0;
      if (!user) {
        reject(new Error(this.instance.$t(`spe.print.error.NotNull`, { fieldName: 'usr' }).toString()));
      }
      const data: IPrinting = new Printing(
        new Printing_GRP1('ZLABEL-MKI', '', this.baseLanguage, 0, ''),
        [
          new Printing_GRP2('sitedeb', site),
          new Printing_GRP2('sitefin', site),
          new Printing_GRP2('mfgtrknumdeb', tracking),
          new Printing_GRP2('mfgtrknumfin', tracking),
          new Printing_GRP2('zitmtypdeb', '1'),
          new Printing_GRP2('zitmtypfin', '1'),
          new Printing_GRP2('usr', user),
          new Printing_GRP2('etat', 'ZLABEL-MKI'),
          new Printing_GRP2('numedt', '0'),
        ],
        new Printing_GRP3(user),
      );
      resolve(this.printingService().print(data));
    });
  }
}
