import { Authority } from '@/shared/security/authority';
import ImosScmPartRotationHeaderDetails from '@/entities/imos-scm-part-rotation-header/imos-scm-part-rotation-header-details.component';

const ImosScmPartRotationHeader = () => import('@/entities/acl-010/imos-scm-part-rotation/imos-scm-part-rotation-header.vue');
const ImosScmPartRotationHeaderUpdate = () => import('@/entities/acl-010/imos-scm-part-rotation/imos-scm-part-rotation-header-update.vue');

const CadErpBinding = () => import('@/entities/acl-010/cad-erp-binding/cad-erp-binding.vue');
const CadErpBindingUpdate = () => import('@/entities/acl-010/cad-erp-binding/cad-erp-binding-update.vue');
// const CadErpBindingDetails = () => import('@/entities/acl-010/cad-erp-binding/cad-erp-binding-details.vue');
const CadErpBindingDetails = () => import('@/entities/cad-erp-binding/cad-erp-binding-details.vue');

export default [
  {
    path: '/acl-010/imos-scm-part-rotation-header',
    name: 'ImosScmPartRotationHeader',
    component: ImosScmPartRotationHeader,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/imos-scm-part-rotation-header/new',
    name: 'ImosScmPartRotationHeaderCreate',
    component: ImosScmPartRotationHeaderUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/imos-scm-part-rotation-header/:imosScmPartRotationHeaderId/edit',
    name: 'ImosScmPartRotationHeaderEdit',
    component: ImosScmPartRotationHeaderUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/imos-scm-part-rotation-header/:imosScmPartRotationHeaderId/view',
    name: 'ImosScmPartRotationHeaderView',
    component: ImosScmPartRotationHeaderDetails,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/imos-scm-part-rotation-header/:imosScmPartRotationHeaderId/duplicate',
    name: 'ImosScmPartRotationHeaderDuplicate',
    component: ImosScmPartRotationHeaderUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/cad-erp-binding',
    name: 'CadErpBinding',
    component: CadErpBinding,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/cad-erp-binding/new',
    name: 'CadErpBindingCreate',
    component: CadErpBindingUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/cad-erp-binding/:cadErpBindingId/edit',
    name: 'CadErpBindingEdit',
    component: CadErpBindingUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/cad-erp-binding/:cadErpBindingId/duplicate',
    name: 'CadErpBindingDuplicate',
    component: CadErpBindingUpdate,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/acl-010/cad-erp-binding/:cadErpBindingId/view',
    name: 'CadErpBindingView',
    component: CadErpBindingDetails,
    meta: { authorities: [Authority.USER] },
  },
];
