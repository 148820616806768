import { Authority } from '@/shared/security/authority';

const ProductionTrackingOperation = () =>
  import('@/entities/manufacturing/production-tracking/operation/operation.vue');

export default [
  {
    path: '/production-tracking/operations',
    name: 'production-tracking-operations',
    component: ProductionTrackingOperation,
    meta: { authorities: [Authority.USER] },
  },
];
