/**
 *  Jhipster does not suport web sockets for reactive type apps
 *
 *  This source use the base implementation provided in the non-reactive type
 */

// import * as SockJS from 'sockjs-client';
// import { Observer, map } from 'rxjs';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
// import { RxStomp } from '@stomp/rx-stomp';

// SPE Begin https://www.npmjs.com/package/rxjs-websockets
// import { QueueingSubject } from 'queueing-subject'
// import { Subscription } from 'rxjs'
// import { share, switchMap } from 'rxjs/operators'
// import makeWebSocketObservable, {
//   GetWebSocketResponses,
//   // WebSocketPayload = string | ArrayBuffer | Blob
//   WebSocketPayload,
//   normalClosureMessage,
// } from 'rxjs-websockets';

// this subject queues as necessary to ensure every message is delivered
//const input$ = new QueueingSubject<string>()

import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { retry, Subscription } from 'rxjs';

// SPE End https://www.npmjs.com/package/rxjs-websockets

import { AccountStateStorable } from '@/shared/config/store/account-store';

const DESTINATION_TRACKER = '/topic/tracker';
const DESTINATION_ACTIVITY = '/topic/activity';
const baseUrl = `websocket/zeppelin/topic/acl-247-wip-with-events`;

export default class TrackerService {
  // private rxStomp: RxStomp;
  private wsSubject$: WebSocketSubject<any>;

  // constructor(private router: VueRouter, private store: Store<AccountStateStorable>) {
  //   this.stomp = new RxStomp();
  //   this.router.afterEach(() => this.sendActivity());
  //
  //   this.store.watch(
  //     (_state, getters) => getters.authenticated,
  //     (value, oldValue) => {
  //       if (value === oldValue) return;
  //       if (value) {
  //         return this.connect();
  //       }
  //       return this.disconnect();
  //     }
  //   );
  // }
  constructor(private router: VueRouter, private store: Store<AccountStateStorable>) {
    this.wsSubject$ = webSocket(this.buildUrl());

    this.store.watch(
      (_state, getters) => getters.authenticated,
      (value, oldValue) => {
        if (value === oldValue) return;
        if (value) {
          return this.connect();
        }
        return this.disconnect();
      }
    );
  }

  // get stomp() {
  //   return this.rxStomp;
  // }
  get wsSubject() {
    return this.wsSubject$;
  }

  // set stomp(rxStomp) {
  //   this.rxStomp = rxStomp;
  //   this.rxStomp.configure({
  //     debug: (msg: string): void => {
  //       console.log(new Date(), msg);
  //     },
  //   });
  //   this.rxStomp.connected$.subscribe(() => {
  //     this.sendActivity();
  //   });
  // }

  // private connect(): void {
  //   this.updateCredentials();
  //   return this.rxStomp.activate();
  // }
  // TODO
  private connect(): void {}

  // private disconnect(): Promise<void> {
  //   return this.rxStomp.deactivate();
  // }
  private disconnect(): void {
    if (this.wsSubject$) {
      this.wsSubject$.complete(); // this will trigger closingObserver and closeObserver
    }
  }

  // private buildUrl(): string {
  //   // building absolute path so that websocket doesn't fail when deploying with a context path
  //   const loc = window.location;
  //   const baseHref = document.querySelector('base').getAttribute('href');
  //   const url = '//' + loc.host + baseHref + 'websocket/tracker';
  //   return url;
  // }
  private buildUrl(): string {
    // building absolute path so that websocket doesn't fail when deploying with a context path
    const loc = window.location;
    const baseHref = document.querySelector('base').getAttribute('href');
    const url = '//' + loc.host + baseHref + baseUrl;
    return location.protocol !== 'https:' ? `ws:${url}` : `wss:${url}`;
  }

  // private updateCredentials(): void {
  //   this.rxStomp.configure({
  //     webSocketFactory: () => {
  //       return new SockJS(this.buildUrl());
  //     },
  //   });
  // }

  // private sendActivity(): void {
  //   this.rxStomp.publish({
  //     destination: DESTINATION_ACTIVITY,
  //     body: JSON.stringify({ page: this.router.currentRoute.fullPath }),
  //   });
  // }

  // public subscribe(observer) {
  //   return this.rxStomp
  //     .watch(DESTINATION_TRACKER)
  //     .pipe(map(imessage => JSON.parse(imessage.body)))
  //     .subscribe(observer);
  // }
  public subscribe(observer): Subscription {
    return this.wsSubject$
      .pipe(
        retry()
        // support auto reconnect
      )
      .subscribe(observer);
  }
}
