import '@mdi/font/css/materialdesignicons.css';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  // icons: {
  //   iconfont: 'mdi'
  // },
  theme: {
    themes: {
      light: {
        primary: '#3c3f41',
        secondary: '#b0bec5',
      },
    },
  },
};

export default new Vuetify(opts);
