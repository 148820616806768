import {
  DateUtils,
  ErpDateUtils,
  ErpLocalMenus,
  ErpSoapWebServiceMessageType,
  FunctionAuthorization,
  GardianService,
  IFunctionAuthorization,
  IMaterialTrack,
  IMFG1_1,
  IOperationTrack,
  IPrinting,
  IProductTrack,
  ISawmillProduct,
  IUnit,
  IZMFG as IWorkOrder,
  MaterialTrack,
  MFG0_1,
  MFG0_2,
  MFG1_1,
  MFG1_2,
  MFG1_4,
  MTK0_1,
  MTK1_1,
  MTK1_2,
  MTK2_1,
  MTK2_2,
  MTK3_1,
  OPERATION_RANGE,
  OperationTrack,
  PrintingService,
  ProductTrack,
  QueryService,
  SubprogService,
  SubprogType,
  TrackingService,
  Unit,
  WorkOrderService,
  ZMFG as WorkOrder,
  ZTRTDIMVOL,
  ZTRTDIMVOL_GRP1,
} from '@gv/ammo-astra';
import { AlertService } from '@gv/ammo-grome';
import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import { minValue, required } from 'vuelidate/lib/validators';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import { IStep, Step } from '@/shared/model/spe/sawmill/step.model';
import { AxiosError } from 'axios';
import SawmillQueryService from "@/entities/sawmill/sawmill-query.service";
import SawmillReportService from "@/entities/sawmill/sawmill-report.service";

const ERP_FUNCTION = 'GESMFG';
const METER_UNITS: string[] = ['M', 'ML', 'MT', 'MT1'];
const SQUARE_UNITS: string[] = ['M2', 'MT2'];
const VOLUME_UNITS: string[] = ['M3', 'MT3'];
const WARNING_TRACK_VOLUME = 5;
const QUANTITY_FRACTION_DIGITS = 3;
const DIMENSION_FRACTION_DIGITS = 3;

const validations = {
  productionSite: { required },
  family: { required },
  product: { required },
  quantityOfParts: {
    required,
    numeric: (val: string | null) => (val != null ? /^([+-]?\d+(?:[\.\,]\d+)?)$/.test(val) : false),
    altMinValue: (val: number) => val > 0,
  },
  plannedQuantityInStockUnit: {
    required,
    numeric: (val: unknown) => typeof val === 'number',
    altMinValue: (val: number) => val > 0,
  },
  workOrder: {
    MFG0_1: {
      PLNFCY: { required },
      MFGFCY: { required },
    },
    MFG1_1: {
      $each: {
        ITMREF: { required },
        ZCOL1: { required },
        ZFNS1: { required },
        ZCOL2: { required },
        ZFNS2: { required },
        ZLEN: {
          required,
          numeric: (val: string | null) => (val != null ? /^([+-]?\d+(?:[\.\,]\d+)?)$/.test(val) : false),
          minValue: minValue(0),
        },
        ZWID: {
          required,
          numeric: (val: string | null) => (val != null ? /^([+-]?\d+(?:[\.\,]\d+)?)$/.test(val) : false),
          minValue: minValue(0),
        },
        ZTHI: {
          required,
          numeric: (val: string | null) => (val != null ? /^([+-]?\d+(?:[\.\,]\d+)?)$/.test(val) : false),
          minValue: minValue(0),
        },
        UOM: { required },
        UOMEXTQTY: { required },
        BOMALT: { required },
      },
    },
    MFG1_2: {
      STRDAT: { required },
      ENDDAT: { required },
    },
    MFG1_4: {
      ROUNUM: { required },
      ROUALT: { required },
    },
  },
};

const enum STEP {
  WorkOrder = 0,
  ProductTrack = 1,
  Print = 2,
  MaterialTrack = 3,
  OperationTrack = 4,
}

@Component({ validations })
export default class CreateWoAndTrack extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Provide('sawmillQueryService')
  private sawmillQueryService = () => new SawmillQueryService(this.queryService);

  @Provide('printingService')
  private printingService = () => new PrintingService(AxiosSingleton.getInstance());

  @Provide('sawmillReportService')
  private sawmillReportService = () => new SawmillReportService(this, this.$store, this.printingService);

  @Inject('subprogService')
  private subprogService: () => SubprogService;

  @Provide('workOrderService')
  private workOrderService = () => new WorkOrderService(AxiosSingleton.getInstance());

  @Provide('trackingService')
  private trackingService = () => new TrackingService(AxiosSingleton.getInstance());

  @Prop() readonly showSavingToggle!: boolean | null;

  // --- Function authorization ---
  public functionAuthorization: IFunctionAuthorization = new FunctionAuthorization(ERP_FUNCTION, false, false, false, null, [], [], []);
  public isFunctionAuthorizationFetching = false;

  // --- WorkOrder ---
  public workOrder: IWorkOrder = new WorkOrder(
    new MFG0_1(null, null),
    new MFG0_2('1', '1'),
    [new MFG1_1()],
    new MFG1_2('1', ErpDateUtils.toFormat(DateUtils.nowAsISOString()), ErpDateUtils.toFormat(DateUtils.nowAsISOString())),
    new MFG1_4()
  );

  public isLoading = false;
  public isSaving = false;

  public productionSite: { [k: string]: string } | null = {};

  public showCalendar = false;
  public date = ErpDateUtils.toFormat(DateUtils.nowAsISOString());
  public families: { [k: string]: string }[] = [];
  public family: string | null = null;
  public products: ISawmillProduct[] = [];
  public product: ISawmillProduct | null = null;
  public bomCodes: number[] = [];
  public bomCode: number | null = null;
  public unit: IUnit | null = null;
  public quantityOfParts: number | null = null;

  public productTrack: IProductTrack | null = null;
  public materialTrack: IMaterialTrack | null = null;
  public operationTrack: IOperationTrack | null = null;
  public printing: IPrinting | null = null;

  public dialog: { [k: string]: any } = {
    visible: false,
    title: null,
    text: null,
    action: {
      primary: null,
      secondary: null,
    },
  };

  public steps: IStep[] = this.initSteps();

  public get authorizedProductionSites(): { [k: string]: string }[] {
    return this.functionAuthorization.authorizedSites.filter(it => it.mfgflg0 === 2).map(it => ({ fcy0: it.fcy0, fcynam0: it.fcynam0 }));
  }

  public get formattedDate(): string | null {
    return DateUtils.toFormat(ErpDateUtils.toISOString(this.date), 'DD-MM-YYYY');
  }

  public get productDescription(): string | null {
    if (this.product) {
      return `${this.product.AXX__TEXTE_0} ${this.product.ITM__CFGFLDNUM1_0}x${this.product.ITM__CFGFLDNUM2_0}x${this.product.ITM__CFGFLDNUM3_0}`;
    } else {
      return null;
    }
  }

  public get units(): any[] {
    const packUnitGroup = 'PACK UNIT';
    const stockUnitGroup = 'STOCK UNIT';
    const units: any[] = [];

    if (this.product) {
      for (let i = 0; i < 4; i++) {
        if (this.product[`ITM__PCU_${i}`].trim() !== '') {
          let description = '';

          const packUnit: string = this.product[`ITM__PCU_${i}`];
          // PAC-STK conversion factor
          const packStockFactor = this.product[`ITM__PCUSTUCOE_${i}`];
          description += packStockFactor + ' ' + this.product.ITM__STU_0;

          // PAC-PRT conversion factor
          const packPartsFactor: number = this.product[`ITM__ZPCUPRTCOE_${i}`];
          // PAC-ROW conversion factor
          const packRowsFactor: number = this.product[`ITM__ZPCUROWCOE_${i}`];

          if (packPartsFactor !== 0 || packRowsFactor !== 0) {
            description += ` (${
              (packPartsFactor === 0 ? '' : this.$t('wibaultApp.sawmill.ITM.ZPCUPRTCOE').toString() + ': ' + packPartsFactor) +
              ' ' +
              (packRowsFactor === 0 ? '' : this.$t('wibaultApp.sawmill.ITM.ZPCUROWCOE').toString() + ': ' + packRowsFactor).trim()
            })`;
          }
          if (i === 0) {
            units.push({ header: packUnitGroup });
          }
          units.push(new Unit(packUnit, this.product[`ITM__PCUSTUCOE_${i}`], description, packUnitGroup));
        }
      }
      units.push({ header: stockUnitGroup });
      units.push(new Unit(this.product.ITM__STU_0, 1, '', stockUnitGroup));
    }

    return units;
  }

  public get packUnits(): any[] {
    const packUnits: any[] = [];

    if (this.product) {
      for (let i = 0; i < 4; i++) {
        if (this.product[`ITM__PCU_${i}`].trim() !== '') {
          packUnits.push(this.product[`ITM__PCU_${i}`]);
        }
      }
    }
    return packUnits;
  }

  public get plannedQuantityInStockUnit(): number | null {
    let quantity: number | null = null;

    if (this.product) {
      if (this.unit !== this.units[this.units.length - 1]) {
        quantity = this.unit.conversionFactor * this.quantityOfParts;
      } else {
        const unit = this.product.ITM__STU_0.toLocaleUpperCase();
        const length = this.workOrder.MFG1_1[0].ZLEN;
        const width = this.workOrder.MFG1_1[0].ZWID;
        const thickness = this.workOrder.MFG1_1[0].ZTHI;

        quantity = this.quantityOfParts;

        if (METER_UNITS.includes(unit)) {
          quantity /= Math.pow(10, 3);
        } else if (SQUARE_UNITS.includes(unit)) {
          quantity *= (length * width) / Math.pow(10, 6);
        } else if (VOLUME_UNITS.includes(unit)) {
          quantity *= (length * width * thickness) / Math.pow(10, 9);
        }
      }
      return parseFloat(quantity.toFixed(QUANTITY_FRACTION_DIGITS));
    }
    return quantity;
  }

  public get plannedQuantityInReleaseUnit(): number | null {
    if (!this.product) {
      return 0;
    }
    return this.unit !== this.units[this.units.length - 1] ? this.quantityOfParts : this.plannedQuantityInReleaseUnit;
  }

  private plannedVolume = 0;

  @Watch('workOrder.MFG1_1', { deep: true })
  onMFG1_1Changed(MFG1_1: IMFG1_1[]): void {
    if (this.$v.workOrder.MFG1_1.$each[0].ZLEN.numeric) {
      this.workOrder.MFG1_1[0].ZLEN = parseFloat(String(MFG1_1[0].ZLEN).replace(',', '.'));
    }

    if (this.$v.workOrder.MFG1_1.$each[0].ZWID.numeric) {
      this.workOrder.MFG1_1[0].ZWID = parseFloat(String(MFG1_1[0].ZWID).replace(',', '.'));
    }

    if (this.$v.workOrder.MFG1_1.$each[0].ZTHI.numeric) {
      this.workOrder.MFG1_1[0].ZTHI = parseFloat(String(MFG1_1[0].ZTHI).replace(',', '.'));
    }
  }

  @Watch('productionSite')
  onProductionSiteChanged(site: { [k: string]: string } | null): void {
    this.$emit('site-change', site);
    const value: string | null = site?.fcy0 ?? null;
    this.workOrder.MFG0_1 = new MFG0_1(value, value);
  }

  @Watch('date')
  onDateChanged(date: string): void {
    this.workOrder.MFG1_2 = new MFG1_2('1', date, date);
  }

  @Watch('family')
  onSelectedFamilyChanged(family: string | null) {
    if (!family || (this.product && this.product.ITM__TSICOD_2 !== family)) {
      this.product = null;
    }

    this.retrieveProducts(family);
  }

  @Watch('product')
  onProductChanged(product: ISawmillProduct | null): void {
    this.$emit('product-change', product?.ITM__ITMREF_0);
    if (product) {
      this.isLoading = true;

      if (!this.family) {
        this.family = product.ITM__TSICOD_2;
      }

      // Set pack unit to PL, ATA by this order
      if (this.units.find(it => it.name === 'PL')) {
        this.unit = this.units.find(it => it.name === 'PL');
      } else if (this.units.find(it => it.name === 'ATA')) {
        this.unit = this.units.find(it => it.name === 'ATA');
      } else {
        this.unit = this.units[this.units.length - 1];
      }
      this.quantityOfParts = 1;

      this.retrieveProductBomCodes(this.productionSite.fcy0, product.ITM__ITMREF_0)
        .then(res => {
          this.bomCodes = res.data.map(it => it.BOH__BOMALT_0);
          this.bomCode = this.bomCodes[0];

          if (this.bomCodes.length === 0) {
            throw new Error('No valid BOM code was found for this product!');
          } else {
            const dimensions = this.getDimensions(this.product, this.unit);

            this.workOrder.MFG1_1 = [
              new MFG1_1(
                product.ITM__ITMREF_0,
                product.ITM__ZSTANUM11_0,
                product.ITM__ZSTANUM12_0,
                product.ITM__ZSTANUM11_1,
                product.ITM__ZSTANUM12_1,
                dimensions.length,
                dimensions.width,
                dimensions.thickness,
                this.unit.name,
                this.plannedQuantityInStockUnit,
                this.bomCode
              ),
            ];
            return this.retrieveProductSiteData(this.productionSite.fcy0, product.ITM__ITMREF_0, res.data[0].BOH__BOMALT_0);
          }
        })
        .then(res => {
          this.workOrder.MFG1_4 = new MFG1_4(res.data[0].ITF__MFGROU_0, res.data[0].ITF__MFGROUALT_0);
        })
        .catch(err => {
          this.product = null;
          this.alertService().showError(this, err.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.workOrder.MFG1_1 = [new MFG1_1()];
      this.workOrder.MFG1_4 = new MFG1_4();
      this.bomCodes = [];
      this.bomCode = null;
      this.unit = null;
    }
  }

  @Watch('bomCode')
  onBomCodeChanged(bomCode: number | null, oldBomCode: number | null): void {
    if (bomCode != null) {
      this.workOrder.MFG1_1[0].BOMALT = bomCode;

      this.retrieveProductSiteData(this.productionSite.fcy0, this.product.ITM__ITMREF_0, bomCode)
        .then(res => {
          this.workOrder.MFG1_4 = new MFG1_4(res.data[0].ITF__MFGROU_0, res.data[0].ITF__MFGROUALT_0);
        })
        .catch(err => {
          this.bomCode = oldBomCode;
          this.alertService().showError(this, err.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  @Watch('unit', { immediate: true })
  onUnitChanged(val: Unit): void {
    if (this.product) {
      this.workOrder.MFG1_1[0].UOM = val.name;

      const dimensions = this.getDimensions(this.product, val);

      this.workOrder.MFG1_1[0].ZLEN = dimensions.length;
      this.workOrder.MFG1_1[0].ZWID = dimensions.width;
      this.workOrder.MFG1_1[0].ZTHI = dimensions.thickness;
    }
  }

  @Watch('quantityOfParts')
  onQuantityOfPartsChanged(quantity: number | null): void {
    if (this.$v.quantityOfParts.numeric) {
      this.quantityOfParts = parseFloat(String(quantity).replace(',', '.'));
    }
  }

  @Watch('plannedQuantityInStockUnit')
  onPlannedQuantityInStockUnitChanged(): void {
    this.retrieveVolume();
  }

  @Watch('showSavingToggle')
  onShowSavingToggleChanged(val: boolean | null, oldVal: boolean | null): void {
    if (!val && oldVal) {
      this.goBack();
    }
  }

  created(): void {
    this.retrieveFunctionAuthorization().then(res => {
      if (res) {
        this.setDefaults();
        this.retrieveFamilies();
        this.retrieveProducts(this.family);
      } else {
        this.$router.go(-1);
      }
    });
  }

  public retrieveFunctionAuthorization(): Promise<boolean> {
    return new Promise(resolve => {
      this.isFunctionAuthorizationFetching = true;

      this.gardianService()
        .functionAuthorization({ function: ERP_FUNCTION })
        .then(param => {
          this.functionAuthorization.accessTypeObject = param.accessTypeObject;
          this.functionAuthorization.authorizationSite = param.authorizationSite;
          this.functionAuthorization.authorizedAccessTypeObject = param.authorizedAccessTypeObject;
          this.functionAuthorization.defaultSite = param.defaultSite;
          this.functionAuthorization.authorizedCompanies.push(...param.authorizedCompanies);
          this.functionAuthorization.authorizedSites.push(...param.authorizedSites);
          this.functionAuthorization.authorizedSalesrep.push(...param.authorizedSalesrep);
          this.isFunctionAuthorizationFetching = false;
          resolve(true);
        })
        .catch(error => {
          this.isFunctionAuthorizationFetching = false;
          this.alertService().showHttpError(this, error.response);
          resolve(false);
        });
    });
  }

  public retrieveFamilies(): void {
    this.isLoading = true;
    this.sawmillQueryService()
      .retrieveFamilies()
      .then(res => {
        this.families = this.uniqueFamilies(res.data);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public retrieveProducts(family: string | null): void {
    this.isLoading = true;
    this.sawmillQueryService()
      .retrieveProducts(family, '')
      .then(res => {
        this.products = res.data;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public retrieveProductBomCodes(site: string, product: string): Promise<any> {
    this.isLoading = true;
    return this.sawmillQueryService().retrieveProductBOMCodes(site, product);
  }

  public retrieveProductSiteData(site: string, product: string, bomCode: number): Promise<any> {
    this.isLoading = true;
    return this.sawmillQueryService().retrieveProductSiteData(site, product, bomCode);
  }

  public retrieveVolume(): void {
    if (!this.product || !this.unit || !this.workOrder.MFG1_1[0].ITMREF) {
      this.plannedVolume = 0;
    }

    const payload = new ZTRTDIMVOL(
      new ZTRTDIMVOL_GRP1(
        this.product['ITM__ITMREF_0'],
        this.quantityOfParts,
        this.workOrder.MFG1_1[0].ZLEN,
        this.workOrder.MFG1_1[0].ZWID,
        this.workOrder.MFG1_1[0].ZTHI,
        this.unit.name,
        0
      )
    );

    this.subprogService()
      .run(SubprogType.ZTRTDIMVOL, payload)
      .then(res => {
        this.plannedVolume = res?.GRP1?.WVOL ?? 0;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
        this.plannedVolume = 0;
      });
  }

  public createWorkOrder(data: IWorkOrder): Promise<any> {
    this.steps[STEP.WorkOrder].isSaving = true;
    return this.workOrderService()
      .createWorkOrder(data)
      .then(res =>
        this.succeed(
          STEP.WorkOrder,
          res,
          res.data?.MFG0_1?.MFGNUM,
          'wibaultApp.workOrder.ZMFG.MFG0_1.MFGNUM',
          'wibaultApp.workOrder.error.NotNull'
        )
      )
      .catch(err => this.fail(STEP.WorkOrder, err));
  }

  public createProductTrack(workOrder: IWorkOrder): Promise<any> {
    const data: IProductTrack = new ProductTrack(
      new MTK0_1(workOrder.MFG0_1.MFGFCY, workOrder.MFG0_1.MFGNUM, workOrder.MFG1_2.STRDAT),
      // This quantity is expressed
      //     - in the stock unit for a multi-line release;
      //     - in the release unit for a single-line release.
      new MTK2_1(workOrder.MFG1_1.length === 1 ? this.plannedQuantityInReleaseUnit : this.plannedQuantityInStockUnit),
      [
        new MTK2_2(
          workOrder.MFG1_1[0].ITMREF,
          workOrder.MFG1_1[0].ZCOL1,
          workOrder.MFG1_1[0].ZFNS1,
          workOrder.MFG1_1[0].ZCOL2,
          workOrder.MFG1_1[0].ZFNS2,
          workOrder.MFG1_1[0].ZLEN,
          workOrder.MFG1_1[0].ZWID,
          workOrder.MFG1_1[0].ZTHI,
          this.quantityOfParts,
          this.plannedVolume
        ),
      ]
    );

    this.steps[STEP.ProductTrack].isSaving = true;
    return this.trackingService()
      .createProductTrack(data)
      .then(res =>
        this.succeed(
          STEP.ProductTrack,
          res,
          res.data?.MTK0_1?.MFGTRKNUM,
          'wibaultApp.productTrack.ZMKP.MTK0_1.MFGTRKNUM',
          'wibaultApp.productTrack.error.NotNull'
        )
      )
      .catch(err => this.fail(STEP.ProductTrack, err));
  }

  public createMaterialTrack(workOrder: IWorkOrder): Promise<any> {
    const data: IMaterialTrack = new MaterialTrack(
      new MTK0_1(workOrder.MFG0_1.MFGFCY, workOrder.MFG0_1.MFGNUM),
      new MTK3_1(
        OPERATION_RANGE.MIN,
        OPERATION_RANGE.MAX,
        3,
        1,
        workOrder.MFG1_1.length === 1 ? this.plannedQuantityInReleaseUnit : this.plannedQuantityInStockUnit
      )
    );
    this.steps[STEP.MaterialTrack].isSaving = true;
    return this.trackingService()
      .createMaterialTrack(data)
      .then(res =>
        this.succeed(
          STEP.MaterialTrack,
          res,
          res.data?.MTK0_1?.MFGTRKNUM,
          'wibaultApp.materialTrack.ZMKM.MTK0_1.MFGTRKNUM',
          'wibaultApp.materialTrack.error.NotNull'
        )
      )
      .catch(err => this.fail(STEP.MaterialTrack, err));
  }

  public createOperationTrack(workOrder: IWorkOrder): Promise<any> {
    const data: IOperationTrack = new OperationTrack(
      new MTK0_1(workOrder.MFG0_1.MFGFCY, workOrder.MFG0_1.MFGNUM),
      new MTK1_1(
        OPERATION_RANGE.MIN,
        OPERATION_RANGE.MAX,
        1,
        workOrder.MFG1_1.length === 1 ? this.plannedQuantityInReleaseUnit : this.plannedQuantityInStockUnit
      ),
      [new MTK1_2(0, 5, 0, '', '', 0, 0, 0, 0, false, 0, 0, 0)]
    );
    this.steps[STEP.OperationTrack].isSaving = true;
    return this.trackingService()
      .createOperationTrack(data)
      .then(res =>
        this.succeed(
          STEP.OperationTrack,
          res,
          res.data?.MTK0_1?.MFGTRKNUM,
          'wibaultApp.operationTrack.ZMKO.MTK0_1.MFGTRKNUM',
          'wibaultApp.operationTrack.error.NotNull'
        )
      )
      .catch(err => this.fail(STEP.OperationTrack, err));
  }
  public print(productTrack: IProductTrack): Promise<any> {
    return new Promise(resolve => {
      if (this.product['ITM__ZMKIPRN_0'] !== ErpLocalMenus.NoYes.YES) {
        this.steps[STEP.Print].done = true;
        this.steps[STEP.Print].success = true;
        this.steps[STEP.Print].message = this.$t('spe.print.messages.disablePrintOnProductTrack').toString();

        return resolve({ data: null });
      }
      this.steps[STEP.Print].isSaving = true;
      return this.sawmillReportService()
        .printLabel(productTrack.MTK0_1.MFGFCY, productTrack.MTK0_1.MFGTRKNUM)
        .then((res: any) => {
          this.steps[STEP.Print].isSaving = false;
          this.steps[STEP.Print].done = true;
          this.steps[STEP.Print].success = true;
          this.steps[STEP.Print].message = this.$t('spe.print.messages.success').toString();

          resolve(res);
        })
        .catch(err => this.fail(STEP.Print, err));
    });
  }

  public vuelidateErrors(model: { [k: string]: any }, options?: { [k: string]: any }): string[] {
    // console.log(`Dirty=${model.$dirty}, anyError=${model.$anyError}`);

    // Property received some sort of interaction from the user
    if (!model.$dirty) return [];

    const errors = [];

    if (model.hasOwnProperty('required') && !model.required) {
      errors.push(this.$t('entity.validation.required'));
    }

    if (model.hasOwnProperty('numeric') && !model.numeric) {
      errors.push(this.$t('entity.validation.number'));
    }

    if (model.hasOwnProperty('minLength') && !model.minLength) {
      errors.push(this.$t('entity.validation.minlength', { min: options.min }));
    }

    if (model.hasOwnProperty('maxLength') && !model.maxLength) {
      errors.push(this.$t('entity.validation.maxlength', { max: options.max }));
    }

    if (model.hasOwnProperty('minValue') && !model.minValue) {
      errors.push(this.$t('entity.validation.min', { min: options.min }));
    }

    if (model.hasOwnProperty('maxValue') && !model.maxValue) {
      errors.push(this.$t('entity.validation.max', { max: options.max }));
    }

    if (model.hasOwnProperty('altMinValue') && !model.altMinValue) {
      errors.push(this.$t('entity.validation.altMin', { min: options.min }));
    }

    if (model.hasOwnProperty('altMaxValue') && !model.altMaxValue) {
      errors.push(this.$t('entity.validation.altMax', { max: options.max }));
    }

    return errors;
  }

  public toISOString(formattedDate: string): string {
    return ErpDateUtils.toISOString(formattedDate);
  }

  public toFormat(dateAsISO: string): string {
    return ErpDateUtils.toFormat(dateAsISO);
  }

  public filterProducts(item: ISawmillProduct, queryText: string): boolean {
    return (
      item.AXX__TEXTE_0.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
      item.ITM__ITMREF_0.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
      `${item.ITM__CFGFLDNUM1_0}x${item.ITM__CFGFLDNUM2_0}x${item.ITM__CFGFLDNUM3_0}`
        .toLocaleLowerCase()
        .includes(queryText.toLocaleLowerCase())
    );
  }

  public filterAuthorizedProductionSites(item: any, queryText: string, itemText: string) {
    const code = item.fcy0.toLowerCase();
    const description = item.fcynam0.toLowerCase();
    const searchText = queryText.toLowerCase();

    return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
  }

  public cancel(): void {
    if (!this.isSaving) {
      this.initialize();
      this.$v.$reset();
    } else {
      this.alertService().showError(this, 'Cannot cancel while saving.');
    }
  }

  public beforeSave(data: IWorkOrder): void {
    const volume = this.plannedVolume;

    // Rule out sawmill waste products from volume control
    if (volume === 0 && this.product['ITM__TSICOD_1'] !== 'M1') {
      this.dialog.visible = true;
      this.dialog.title = this.$t('wibaultApp.sawmill.messages.value-cannot-be-zero.header');
      this.dialog.text = this.$t('wibaultApp.sawmill.messages.value-cannot-be-zero.text');
      this.dialog.action.primary = this.$t('wibaultApp.sawmill.messages.average-volume-exceeded.action.primary');
      this.dialog.action.secondary = null;
    } else if (volume > WARNING_TRACK_VOLUME) {
      this.dialog.visible = true;
      this.dialog.title = this.$t('wibaultApp.sawmill.messages.average-volume-exceeded.header');
      this.dialog.text = this.$t('wibaultApp.sawmill.messages.average-volume-exceeded.text', {
        max: WARNING_TRACK_VOLUME,
        volume,
      });
      this.dialog.action.primary = this.$t('wibaultApp.sawmill.messages.average-volume-exceeded.action.primary');
      this.dialog.action.secondary = this.$t('wibaultApp.sawmill.messages.average-volume-exceeded.action.secondary');
    } else {
      data.MFG1_1[0].UOMEXTQTY = this.packUnits.includes(this.unit.name) ? this.quantityOfParts : this.plannedQuantityInStockUnit;
      this.save(data);
    }
  }

  public save(data: IWorkOrder): void {
    if (!this.isSaving) {
      this.isSaving = true;
      if (this.$v.$invalid) {
        this.alertService().showError(this, 'Invalid data; Cannot create work order!');
      } else {
        this.$emit('track-created');

        this.createWorkOrder(data)
          .then(res => {
            this.workOrder = res.data;

            const productTrack = this.createProductTrack(this.workOrder).then(res => {
              this.steps[STEP.ProductTrack].isSaving = false;
              this.productTrack = res.data;

              this.steps[STEP.Print].isSaving = true;
              return this.print(this.productTrack).then(res => {
                this.steps[STEP.Print].isSaving = false;
                return res;
              });
            });

            const materialTrack = this.createMaterialTrack(this.workOrder).then(res => {
              this.steps[STEP.MaterialTrack].isSaving = false;
              this.materialTrack = res.data;
            });

            const operationTrack = this.createOperationTrack(this.workOrder).then(res => {
              this.steps[STEP.OperationTrack].isSaving = false;
              this.operationTrack = res.data;
            });

            Promise.all([productTrack, materialTrack, operationTrack]).then(() => {});
          })
          .finally(() => this.$emit('saving-done'));
      }
    } else {
      this.alertService().showError(this, 'Already saving, please wait.');
    }
  }

  public goBack(): void {
    this.isSaving = false;

    this.clearSteps();

    const dimensions = this.getDimensions(this.product, this.unit);

    this.workOrder = new WorkOrder(
      new MFG0_1(this.productionSite.fcy0, this.productionSite.fcy0),
      new MFG0_2('1', '1'),
      [
        new MFG1_1(
          this.product.ITM__ITMREF_0,
          this.product.ITM__ZSTANUM11_0,
          this.product.ITM__ZSTANUM12_0,
          this.product.ITM__ZSTANUM11_1,
          this.product.ITM__ZSTANUM12_1,
          dimensions.length,
          dimensions.width,
          dimensions.thickness,
          this.unit.name,
          this.plannedQuantityInStockUnit,
          this.bomCode
        ),
      ],
      new MFG1_2('1', ErpDateUtils.toFormat(DateUtils.nowAsISOString()), ErpDateUtils.toFormat(DateUtils.nowAsISOString())),
      new MFG1_4()
    );
    this.retrieveProductSiteData(this.productionSite.fcy0, this.product.ITM__ITMREF_0, this.bomCode)
      .then(res => {
        this.workOrder.MFG1_4 = new MFG1_4(res.data[0].ITF__MFGROU_0, res.data[0].ITF__MFGROUALT_0);
      })
      .catch(err => {
        this.initialize();
        this.alertService().showError(this, err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.$v.$reset();
  }

  /**
   * Set new entity defaults
   * @private
   */
  private setDefaults() {
    /* Site */
    const site = this.functionAuthorization.authorizedSites.find(it => it.fcy0 === this.functionAuthorization.defaultSite);
    if (site) {
      this.productionSite = site;
    }

    /* Posted date */
    this.date = ErpDateUtils.toFormat(DateUtils.nowAsISOString());
  }

  private initialize(): void {
    this.setDefaults();
    this.date = ErpDateUtils.toFormat(DateUtils.nowAsISOString());
    this.family = null;
    this.quantityOfParts = null;
    this.clearSteps();
  }

  private initSteps(): IStep[] {
    return [
      new Step(this.$t('wibaultApp.workOrder.detail.title').toString()),
      new Step(this.$t('wibaultApp.productTrack.detail.title').toString()),
      new Step(this.$t('spe.print.home.title').toString()),
      new Step(this.$t('wibaultApp.materialTrack.detail.title').toString()),
      new Step(this.$t('wibaultApp.operationTrack.detail.title').toString()),
    ];
  }

  private clearSteps(): void {
    this.productTrack = null;
    this.materialTrack = null;
    this.operationTrack = null;
    this.printing = null;

    this.steps = this.initSteps();
  }

  /**
   * Find unique families in array based on multiple properties
   * @param families
   */
  private uniqueFamilies(families: { [k: string]: string }[]): { [k: string]: string }[] {
    const unfilteredFamilies = families.map(it => ({
      ITM__TSICOD_2: it.ITM__TSICOD_2,
      AXX__TSIDES_2: it.AXX__TSIDES_2,
    }));

    // Isolate disitinct ids
    const ids: Array<string> = Array.from(new Set(unfilteredFamilies.map(it => it.ITM__TSICOD_2)));

    // Rebuild the object with the id and description
    return ids.map(id => ({
      ITM__TSICOD_2: id,
      AXX__TSIDES_2: unfilteredFamilies.find(it => it.ITM__TSICOD_2 === id).AXX__TSIDES_2,
    }));
  }

  private getDimensions(product: ISawmillProduct, unit: IUnit): { [k: string]: any } {
    const length: number = product.ITM__CFGFLDNUM1_0;
    const width: number =
      this.packUnits.includes(unit.name) && product.ITM__CFGFLDNUM2_0 === 0 && product.ITM__CFGFLDNUM3_0 !== 0
        ? (this.unit.conversionFactor / product.ITM__CFGFLDNUM1_0) * Math.pow(10, 6)
        : product.ITM__CFGFLDNUM2_0;
    const thickness: number = product.ITM__CFGFLDNUM3_0;

    return { length, width: parseFloat(width.toFixed(DIMENSION_FRACTION_DIGITS)), thickness };
  }

  /**
   * Helper function for then step
   */
  private succeed(step: number, res: any, key: string, fieldNameTranslateKey: string, errorTranslateKey: string): any {
    this.steps[step].isSaving = false;
    this.steps[step].done = true;
    this.steps[step].success = true;
    this.steps[step].response = res;

    if (key) {
      this.steps[step].message = key;
      return res;
    } else {
      const fieldName: string = this.$t(fieldNameTranslateKey).toString();
      throw new Error(this.$t(errorTranslateKey, { fieldName }).toString());
    }
  }

  /**
   * Helper function for failling step
   */
  private fail(step: number, err: Error | AxiosError): void {
    this.steps[step].isSaving = false;
    this.steps[step].done = true;
    this.steps[step].success = false;
    this.steps[step].message = err.message;
    this.steps[step].response = err;

    throw err;
  }

  public hasThreeLine(step: IStep): boolean {
    const res = step.response instanceof AxiosError ? step.response.response : step.response;

    if (res?.data?.messages) {
      return res.data.messages.length > 1;
    }
    return false;
  }

  /**
   * Returns the subtitle as html
   */
  public toSubtitle(step: IStep): string | null {
    if (!step.message) {
      return null;
    }

    const res = step.response instanceof AxiosError ? step.response.response : step.response;

    if (!step.success && res?.data?.messages) {
      return res.data.messages.reduce((a, c) => {
        let color: string | null;
        // @ts-ignore
        const theme = this.$vuetify.theme.defaults[this.$vuetify.theme.isDark ? 'dark' : 'light'];

        switch (c.type) {
          case ErpSoapWebServiceMessageType.INFO:
            color = theme.info;
            break;
          case ErpSoapWebServiceMessageType.WARNING:
            color = theme.warning;
            break;
          case ErpSoapWebServiceMessageType.ERROR:
            color = theme.error;
            break;
        }
        a += color ? `<div style="color: ${color}">${c.message}</div>` : `<div>${c.message}</div>`;
        return a;
      }, '');
    } else {
      return `<span class="text--primary">${step.message}</span>`;
    }
  }
}
