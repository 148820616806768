import Sawmill from '@/entities/sawmill/create-work-order-and-track/sawmill.vue';
import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sawmill/create-work-order-and-track',
    name: 'sawmill-create-work-order-and-track',
    component: Sawmill,
    meta: { authorities: [Authority.USER] },
  },
];
