import { Component, Emit, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import { LoginService, AccountService, AlertService, TranslationService } from '@gv/ammo-grome';
import { AuroraService } from '@gv/ammo-astra';

import { GoLeftDrawerComponent as GoLeftDrawer } from '@gv/ammo-grome';
import { Authority } from '@/shared/security/authority';

const ROLE_PREFIX = 'ROLE_';

@Component({
  components: {
    'go-left-drawer': GoLeftDrawer,
  },
})
export default class JhiNavbarExtended extends Vue {
  // SPE
  @Provide('auroraService') private auroraService = () => new AuroraService();

  @Inject('alertService') private alertService!: () => AlertService;

  @Emit('click')
  // tslint:disable-next-line:no-empty
  leftBubbleUp(e: any) {}
  // SPE

  @Prop(String) menu!: string;
  @Prop(String) resolveLocalPrefix!: string;

  private items: Array<any> = [];
  private authorizedItems: Array<any> = [];

  //
  // Begin copy from src/main/webapp/app/core/jhi-navbar/jhi-navbar.component.ts
  //
  @Inject('loginService') private loginService: () => LoginService;
  @Inject('translationService')
  private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};

  @Watch('hasAnyAuthorityValues', { immediate: true, deep: true })
  onhasAnyAuthorityValuesChanged(new_val: { [k: string]: any }) {
    this.setAuthorizedItems(new_val);
  }

  public setAuthorizedItems(authorities: { [k: string]: any }): void {
    this.authorizedItems = this.items
      .filter(it => this.isAuthorized(it, authorities))
      .map(it => {
        if (it.children && it.children.length) {
          it.children = it.children.filter(child => this.isAuthorized(child, authorities));
        }

        return it;
      });
  }

  created(): void {
    this.translationService().refreshTranslation(this.currentLanguage);
    this.retrieve();
  }

  public subIsActive(input): boolean {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    return this.loginService()
      .logout()
      .then(response => {
        this.$store.commit('logout');
        window.location.href = response.data.logoutUrl;
        const next = response.data?.logoutUrl ?? '/';
        if (this.$route.path !== next) {
          return this.$router.push(next);
        }
      });

    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    // SPE Begin handle empty authorities
    if (Array.isArray(authorities) && authorities.length === 0) {
      return true;
    }
    // SPE End handle empty authorities
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        // SPE the following line will add the property but vue will not know, is not reactive
        // this.hasAnyAuthorityValues[authorities] = value;
        // Using Vue.set(), we’re letting Vue know that we’re creating another property and it’ll track that property now
        this.$set(this.hasAnyAuthorityValues, authorities, value);
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }
  //
  // End copy from src/main/webapp/app/core/jhi-navbar/jhi-navbar.component.ts
  //

  //
  /**
   * SPE: Maps access accessing roles
   * @param authorities
   */
  public mapRole(authorities: any): string[] {
    // Handle undefined or null
    if (authorities == null || authorities === '') {
      return [];
    }

    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    return authorities.map((it: string) => it.toUpperCase()).map((it: string) => (it.startsWith(ROLE_PREFIX) ? it : ROLE_PREFIX + it));
  }

  public adminItems: any[] = [
    // Administration
    {
      // administration
      id: 9000,
      name: 'global.menu.admin.main',
      type: 'static-url',
      value: '#',
      target: '_self',
      parameters: null,
      enabled: true,
      icon: 'mdi-cogs',
      accessing_permissions: '',
      accessing_roles: Authority.ADMIN,
      children: [
        {
          // gateway
          id: 9001,
          name: 'global.menu.admin.gateway',
          type: 'static-url',
          value: '/admin/gateway',
          target: '_self',
          parameters: null,
          enabled: true,
          icon: 'mdi-road',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },
        {
          // metrics
          id: 9002,
          name: 'global.menu.admin.metrics',
          type: 'static-url',
          value: '/admin/metrics',
          target: '_self',
          parameters: null,
          enabled: true,
          icon: 'mdi-gauge',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },
        {
          // health
          id: 9003,
          name: 'global.menu.admin.health',
          type: 'static-url',
          value: '/admin/health',
          target: '_self',
          parameters: null,
          enabled: true,
          icon: 'mdi-heart',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },
        {
          // configuration
          id: 9004,
          name: 'global.menu.admin.configuration',
          type: 'static-url',
          value: '/admin/configuration',
          target: '_self',
          parameters: null,
          enabled: true,
          icon: 'mdi-cog',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },
        {
          // logs
          id: 9005,
          name: 'global.menu.admin.logs',
          type: 'static-url',
          value: '/admin/logs',
          target: '_self',
          parameters: null,
          enabled: true,
          icon: 'mdi-list-status',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },
        {
          // API
          id: 9006,
          name: 'global.menu.admin.apidocs',
          type: 'static-url',
          value: '/admin/docs',
          target: '_self',
          parameters: null,
          enabled: true, // this.openAPIEnabled,
          icon: 'mdi-text-box',
          accessing_permissions: '',
          accessing_roles: Authority.ADMIN,
          children: [],
        },

        // {
        //   id: 9007,
        //   name: 'database',
        //   title: 'Database',
        //   type: 'static-url',
        //   value: 'http://localhost:8092',
        //   target: '_self',
        //   parameters: null,
        //   enabled: true,
        //   icon: 'mdi-database',
        //   accessing_permissions: 'admin',
        //   accessing_roles: null,
        //   children: [],
        // },
      ],
    },
  ];
  // SPE end

  private isAuthorized(menu: { [k: string]: any }, authorities: any): boolean {
    const roles: Array<string> = this.mapRole(menu.accessing_roles);

    // No roles were specified
    if (Array.isArray(roles) && roles.length === 0) {
      return true;
    }

    // Require if is a missing role
    this.hasAnyAuthority(roles);

    return roles.some(it => authorities[it] ?? false);
  }

  public retrieve(): void {
    this.auroraService()
      .sideBarMenu(this.menu)
      .then(res => {
        this.items = res.concat(this.adminItems);
        this.setAuthorizedItems(this.hasAnyAuthorityValues);
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
}
