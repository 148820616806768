export const maintenanceCodes = [
  {
    code: '01',
    description: 'Afiação de serras',
  },
  {
    code: '02',
    description: 'Substituição de rolamentos',
  },
  {
    code: '03',
    description: 'Substituição de correias',
  },
  {
    code: '04',
    description: 'Lubrificação de equipamentos',
  },
  {
    code: '05',
    description: 'Substituição de lâminas',
  },
  {
    code: '06',
    description: 'Limpeza de pó da serra',
  },
  {
    code: '07',
    description: 'Ajuste de tensão de correias',
  },
  {
    code: '08',
    description: 'Reparação de motor elétrico',
  },
  {
    code: '09',
    description: 'Manutenção de sistemas hidráulicos',
  },
  {
    code: '10',
    description: 'Revisão geral do equipamento',
  },
];

export default class MockService {
  public retrieveMaintenanceCodes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(maintenanceCodes);
    });
  }
}
